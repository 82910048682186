import * as TogglePrimitives from '@radix-ui/react-toggle-group';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const toggleItemStyles = cva(
  'flex flex-row [&>button]:flex [&>button]:flex-row [&>button]:items-center [&>button]:justify-center [&>button]:text-center ',
  {
    variants: {
      variant: {
        primary: `inline-flex h-10 w-full rounded-full bg-neutral-100 md:w-auto [&>button]:w-full [&>button]:rounded-full [&>button]:from-[#885AFD] [&>button]:to-[#00193F] [&>button]:text-neutral-1000 data-[state=on]:[&>button]:bg-gradient-to-l
        data-[state=on]:[&>button]:text-neutral-100 [&>button]:md:w-52`,
        purpleDark:
          'rounded-full bg-primary-1000 p-1 text-primary-200 [&>button]:rounded-full [&>button]:px-3 [&>button]:py-2 data-[state=on]:[&>button]:bg-primary-800 data-[state=on]:[&>button]:text-neutral-100',
        purpleGraySquare:
          'inline-flex overflow-hidden rounded-lg [&>button:first-child]:rounded-l-lg data-[state=off]:[&>button:first-child]:border-r-0 [&>button:last-child]:rounded-r-lg data-[state=off]:[&>button:last-child]:border-l-0 [&>button]:border [&>button]:px-4 [&>button]:py-2 data-[state=off]:[&>button]:border-neutral-400 data-[state=on]:[&>button]:border-primary-600 data-[state=off]:[&>button]:bg-neutral-1000 data-[state=on]:[&>button]:bg-primary-100 data-[state=off]:[&>button]:text-neutral-400 data-[state=on]:[&>button]:text-primary-600',
        primaryRoundedOutline:
          'gap-2 text-neutral-100 [&>button]:rounded-full [&>button]:border [&>button]:border-primary-600  [&>button]:px-4  [&>button]:py-1 [&>button]:transition-colors data-[state=on]:[&>button]:bg-primary-600',
        secondary:
          'gap-2 text-neutral-1000 [&>button]:px-2 [&>button]:py-1  data-[state=on]:[&>button]:border-b-2 data-[state=on]:[&>button]:border-primary-600 data-[state=on]:[&>button]:text-primary-600',
        unstyled: '',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof TogglePrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitives.Root> &
    VariantProps<typeof toggleItemStyles>
>(({ className, children, variant, ...props }, ref) => (
  <TogglePrimitives.Root
    ref={ref}
    className={cn(toggleItemStyles({ variant }), className)}
    {...props}
  >
    {children}
  </TogglePrimitives.Root>
));

const ToggleItem = React.forwardRef<
  React.ElementRef<typeof TogglePrimitives.Item>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitives.Item>
>(({ className, children, ...props }, ref) => (
  <TogglePrimitives.Item ref={ref} className={cn(className)} {...props}>
    {children}
  </TogglePrimitives.Item>
));

ToggleGroup.displayName = TogglePrimitives.Root.displayName;
ToggleItem.displayName = TogglePrimitives.Item.displayName;
export { ToggleGroup, ToggleItem };
